<template>
  <el-dialog
    v-model="dialog"
    :title="$t.translate('TTL_SERVICE_TAGS')"
    custom-class="cus-cont"
  >
    <!-- <label>{{ $t.translate("FRM_TAG_CODE") }}</label>
    <el-input
      v-model="formData.TagCode"
      v-on:keyup="formData.TagCode = formData.TagCode.toUpperCase().replace(/ /g, '')"
      :placeholder="$t.translate('FRM_TAG_CODE')"
      class="mb-3"
      :class="{ 'inp-invalid': $h.isBlank(formData.TagCode) }"
    /> -->

    <label>{{ $t.translate("FRM_NAME") }} {{ $t.translate("LANG_EN") }}</label>
    <el-input
      v-model="formData.Name"
      :placeholder="$t.translate('FRM_NAME')"
      class="mb-3"
      :class="{ 'inp-invalid': $h.isBlank(formData.Name) }"
    />

    <label>{{ $t.translate("FRM_NAME") }} {{ $t.translate("LANG_CHS") }}</label>
    <el-input
      v-model="formData.NameCHS"
      :placeholder="$t.translate('FRM_NAME')"
      class="mb-3"
    />

    <label>{{ $t.translate("FRM_NAME") }} {{ $t.translate("LANG_CHT") }}</label>
    <el-input
      v-model="formData.NameCHT"
      :placeholder="$t.translate('FRM_NAME')"
      class="mb-3"
    />

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialog = false">{{ $t.translate("BTN_CANCEL") }}</el-button>
        <el-button type="primary" @click="submitTag">{{
          $t.translate("BTN_CONFIRM")
        }}</el-button>
      </span>
    </template>
  </el-dialog>

  <div>
    <div class="container-fluid pt-8 pb-5">
      <el-button type="primary" @click="addTag" class="mb-4">
        {{ $t.translate("BTN_ADD") }}
      </el-button>

      <div class="shadow bg-white p-around">
        <Table
          url="/assessment/tag/list"
          ref="filttable"
          :viewData="handleEdit"
          height="64vh"
        >
          <!-- <el-table-column
            :label="$t.translate('FRM_TAG_CODE')"
            prop="TagCode"
            min-width="140"
          ></el-table-column> -->

          <el-table-column
            :label="$t.translate('FRM_NAME')"
            prop="Name"
            min-width="140"
          ></el-table-column>

          <el-table-column
            :label="$t.translate('FRM_NAME') + ' ' + $t.translate('LANG_CHS')"
            prop="NameCHS"
            min-width="140"
          ></el-table-column>

          <el-table-column
            :label="$t.translate('FRM_NAME') + ' ' + $t.translate('LANG_CHT')"
            prop="NameCHT"
            min-width="140"
          ></el-table-column>

          <el-table-column
            :label="$t.translate('LBL_ACTION')"
            width="100"
            class-name="pl-1 action-col"
          >
            <template #default="scope">
              <el-button
                size="mini"
                type="danger"
                @click="handleDelete(scope.$index, scope.row)"
                ><i class="fa fa-times"></i
              ></el-button>
            </template>
          </el-table-column>
        </Table>
      </div>
    </div>
  </div>
</template>
<script>
import Table from "@/components/Table.vue";
import { reactive, onMounted, inject, ref } from "vue";
import { get, post, isValidResponse } from "@/utils/axios";
import { translation } from "@/utils/translation";
import { router } from "@/utils/router";
import { ElMessageBox } from "element-plus";
import { useRoute } from "vue-router";

export default {
  components: { Table },
  setup() {
    let filttable = ref("filttable");
    let dialog = ref(false);
    let formData = reactive({
      AssessmentServiceTagId: "",
      TagCode: "",
      Name: "",
      NameCHS: "",
      NameCHT: "",
    });

    const handleEdit = async (
      { AssessmentServiceTagId, TagCode, Name, NameCHS = null, NameCHT = null },
      row
    ) => {
      if (row && row?.className?.indexOf("action-col") > -1) return;
      formData.AssessmentServiceTagId = AssessmentServiceTagId;
      formData.TagCode = TagCode;
      formData.Name = Name;
      formData.NameCHS = NameCHS;
      formData.NameCHT = NameCHT;
      dialog.value = true;
    };

    const handleDelete = async (index, { TagCode }) => {
      try {
        let confirmed = await ElMessageBox.confirm(
          translation.translate("MSG_WARNING_CONFIRM_DELETE"),
          translation.translate("TTL_WARNING"),
          {
            confirmButtonText: translation.translate("BTN_OKAY"),
            cancelButtonText: translation.translate("BTN_CANCEL"),
            type: "warning",
          }
        );
        let res = await post("/assessment/tag/remove", { TagCode });
        if (!isValidResponse(res)) return;
        filterAction();
      } catch (err) {
        //err
      }
    };

    let filterAction = async () => {
      await filttable.value.handleCurrentChange(1);
    };

    const submitTag = async () => {
      let res = await post("/assessment/tag/submit", formData);
      if (!isValidResponse(res)) return;
      filterAction();

      dialog.value = false;
    };

    const addTag = () => {
      for (let key of Object.keys(formData)) {
        formData[key] = "";
      }
      dialog.value = true;
    };

    return {
      addTag,
      submitTag,
      formData,
      dialog,
      filttable,
      handleDelete,
      handleEdit,
      filterAction,
    };
  },
};
</script>
<style></style>
